<template>
  <b-card>
    <b-overlay
      :show="loadingDetail || loadingSubmit"
    >
      <validation-observer ref="formValidation">
        <b-form
          ref="form"
          class="mt-50"
          @submit.prevent=""
        >
          <!-- Title -->
          <b-row class="mb-2">
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                label="Judul"
                required
                class="mb-75"
              />
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                name="Judul"
                :rules="getRules(items.title)"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="{ 'invalid': errors.length > 0 }"
                >
                  <b-form-input
                    v-model="items.title.value"
                    :type="items.title.type || 'text'"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Key -->
          <b-row class="mb-2">
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                label="Key"
                required
                class="mb-75"
              />
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                name="Key"
                :rules="getRules(items.key)"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="{ 'invalid': errors.length > 0 }"
                >
                  <b-form-input
                    v-model="items.key.value"
                    :type="items.key.type || 'text'"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Type -->
          <b-row class="mb-2">
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                label="Tipe"
                required
                class="mb-75"
              />
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipe"
                :rules="getRules(items.type)"
              >
                <v-select
                  v-model="items.type.selected"
                  placeholder="Pilih"
                  label="text"
                  :options="items.type.options"
                  :selectable="option => ! option.value.includes('select_value')"
                >
                  <!-- option -->
                  <template v-slot:option="option">
                    {{ option[items.text || 'text'] }}
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Value -->
          <b-row
            class="mb-2"
          >
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                label="Nilai"
                required
                class="mb-75"
              />
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                name="Nilai"
                :rules="getRules(items.value)"
              >
                <!-- Switch -->
                <b-form-checkbox
                  v-if="items.type.selected.value === 'switch'"
                  v-model="items.value.value"
                  switch
                  inline
                >
                  {{ items.text }}
                </b-form-checkbox>

                <!-- Number -->
                <b-input-group
                  v-else-if="items.type.selected.value === 'number'"
                  class="input-group-merge"
                  :class="{ 'invalid': errors.length > 0 }"
                >
                  <b-form-input
                    v-model="items.value.value"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>

                <!-- Text -->
                <b-input-group
                  v-else-if="items.type.selected.value === 'text'"
                  class="input-group-merge"
                  :class="{ 'invalid': errors.length > 0 }"
                >
                  <b-form-input
                    v-model="items.value.value"
                    type="text"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>

                <!-- Description -->
                <b-form-textarea
                  v-else-if="items.type.selected.value === 'description'"
                  v-model="items.value.value"
                  rows="3"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Description -->
          <b-row class="mb-2">
            <b-col
              md="4"
              :xl="block ? 4 : 3"
            >
              <FormLabel
                label="Deskripsi"
                class="mb-75"
              />
            </b-col>

            <!-- Fields -->
            <b-col
              md="8"
              :xl="block ? 8 : 6"
            >
              <validation-provider
                #default="{ errors }"
                name="Deskripsi"
                :rules="getRules(items.description)"
              >
                <!-- Textarea -->
                <b-form-textarea
                  v-model="items.description.value"
                  :rows="items.description.rows || 3"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Submit button -->
          <div class="border-top pt-2">
            <b-form-row class="justify-content-end">
              <!-- Cancel -->
              <b-col cols="auto">
                <b-button
                  variant="flat-dark"
                  @click="cancel()"
                >
                  Cancel
                </b-button>
              </b-col>
              <!-- Save and stay still, do not show on default form -->
              <b-col
                cols="auto"
              >
                <b-button
                  v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                  :disabled="loadingSubmit || !isSubmitAllowed"
                  type="submit"
                  variant="primary"
                  class="d-flex align-items-center mb-75"
                  @click="submit('stay')"
                >
                  <b-spinner
                    v-if="loadingSubmit"
                    class="mr-50"
                    small
                  />
                  <span>Simpan & Lanjut Ubah</span>
                </b-button>
              </b-col>
              <!-- Save and return, do not show on default form -->
              <b-col
                cols="auto"
              >
                <b-button
                  v-b-tooltip.v-dark.top="{ title: 'No changes', disabled: isSubmitAllowed }"
                  :disabled="loadingSubmit || !isSubmitAllowed"
                  type="submit"
                  variant="primary"
                  class="d-flex align-items-center"
                  @click="submit('return')"
                >
                  <b-spinner
                    v-if="loadingSubmit"
                    class="mr-50"
                    small
                  />
                  <span>Simpan Pengaturan</span>
                </b-button>
              </b-col>
            </b-form-row>
          </div>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard, BCol, BForm, BFormCheckbox, BFormInput, BFormRow, BFormTextarea, BInputGroup,
  BOverlay, BRow, BSpinner,
} from 'bootstrap-vue'
import {
  ValidationProvider, ValidationObserver, extend, localize,
} from 'vee-validate'
import FormLabel from '@/layouts/components/FormLabel.vue'
import vSelect from 'vue-select'
import { required, regex } from 'vee-validate/dist/rules'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

extend('required', required)
extend('regex', regex)

localize({
  id: {
    messages: {
      required: '{_field_} harus diisi',
      regex: '{_field_} tidak valid',
    },
    names: {
      title: 'Judul',
      key: 'Key',
      description: 'Deskripsi',
      type: 'Tipe',
      value: 'Nilai',
    },
  },
})

localize('id')

export default {
  components: {
    BSpinner,
    BFormRow,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BFormInput,
    BInputGroup,
    ValidationProvider,
    FormLabel,
    BCol,
    BRow,
    BForm,
    ValidationObserver,
    BCard,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      block: false,
      isSubmitAllowed: true,
      submitType: '',
      initialLoad: true,
      items: {
        title: {
          key: 'title',
          label: 'Judul',
          type: 'text',
          value: '',
          required: true,
        },
        key: {
          key: 'key',
          label: 'Key',
          type: 'text',
          value: '',
          required: true,
          regex: '^[a-z0-9_]+$',
        },
        description: {
          key: 'description',
          label: 'Deskripsi',
          type: 'textarea',
          value: '',
          rows: 8,
        },
        type: {
          key: 'type',
          label: 'Tipe',
          type: 'select',
          value: '',
          selected: '',
          options: [
            {
              value: 'select_value',
              text: 'Pilih Tipe',
            },
            {
              value: 'switch',
              text: 'Switch',
            },
            {
              value: 'number',
              text: 'Number',
            },
            {
              value: 'text',
              text: 'Text',
            },
            {
              value: 'description',
              text: 'Description',
            },
          ],
          required: true,
        },
        value: {
          key: 'value',
          label: 'Nilai',
          type: '',
          value: '',
          required: true,
        },
      },
    }
  },
  computed: {
    loadingSubmit() {
      return this.$store.state.settings.loadingSubmit
    },
    loadingDetail() {
      return this.$store.state.settings.loadingDetail
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'items.type.selected': function (newType) {
      if (this.initialLoad) {
        this.initialLoad = false
      } else {
        switch (newType) {
          case 'switch':
            this.items.value.value = false
            break
          case 'number':
            this.items.value.value = 0
            break
          case 'text':
          case 'description':
            this.items.value.value = ''
            break
          default:
            this.items.value.value = ''
        }
      }
    },
    '$store.state.settings.detailData': {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.items.title.value = newValue.title
          this.items.key.value = newValue.key
          this.items.description.value = newValue.description
          this.items.type.selected = this.items.type.options.find(option => option.value === newValue.type)
          this.items.value.value = newValue.value
        }
      },
    },
  },
  mounted() {
    this.getSetting(this.$route.params.id)
  },
  methods: {
    getSetting(id) {
      this.$store.dispatch('settings/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('settings/update', {
        id,
        data,
      })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('settings/SET_DETAIL_DATA', undefined)
    },
    getRules(field) {
      const rules = []

      if (field.required) {
        rules.push('required')
      }

      if (field.type === 'email') {
        rules.push('email')
      }

      if (field.minLength) {
        rules.push(`min:${field.minLength}`)
      }

      if (field.maxLength) {
        rules.push(`max:${field.maxLength}`)
      }

      if (field.min) {
        rules.push(`min_value:${field.min}`)
      }

      if (field.max) {
        rules.push(`max_value:${field.max}`)
      }

      if (field.regex) {
        rules.push(`regex:${field.regex}`)
      }

      return rules.join('|')
    },
    submit(type) {
      const data = {
        title: this.items.title.value,
        key: this.items.key.value,
        description: this.items.description.value,
        type: this.items.type.selected.value,
        value: String(this.items.value.value),
      }
      this.$refs.formValidation.validate()
        .then(async success => {
          if (success) {
            this.submitType = type
            const { id } = this.$route.params
            this.$store.dispatch('settings/update', {
              id,
              data,
            })
              .then(() => {
                // Panggil submitCallback jika ada
                if (this.submitCallback) {
                  this.submitCallback()
                }
              })
          }
        })
    },
    submitCallback() {
      let successMessage = this.successMessage || 'The data has been submitted'

      successMessage = 'Pengaturan berhasil diperbarui.'

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sukses',
          icon: 'CheckIcon',
          text: successMessage,
          variant: 'success',
        },
      })

      if (this.update && this.submitType === 'stay') {
        this.isSubmitAllowed = false
      }

      if (this.submitType === 'return') {
        this.$router.back()
      }
    },
    reset() {
      this.items.title.value = ''
      this.items.key.value = ''
      this.items.description.value = ''
      this.items.type.selected = ''
      this.items.value.value = ''
    },
    cancel() {
      this.$router.back()
    },
  },
}
</script>

<style scoped>

</style>
